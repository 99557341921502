import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import ManualPunch from '../ManualPunch';
import { useNavigate } from 'react-router-dom';
// import ManualPunchDetail from '../../components/ManualPunchDetail';

const EmployeeCard = () => {
    const location = useLocation();
    const { id } = useParams();
    const employeeData = location.state?.employee;
    const [qrCodeData, setQrCodeData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQRCode = async () => {
            try {
                const response = await fetch(`https://timestation.clickbooster.io/api/employees/qr/${employeeData.id}`);
                const data = await response.json();
                if (data.status === "success") {
                    setQrCodeData(data.data);
                }
            } catch (error) {
                console.error('Error fetching QR code:', error);
            }
        };

        if (employeeData?.id) {
            fetchQRCode();
        }
    }, [employeeData]);

    const handlePrintCard = async () => {
        try {
            const response = await fetch(`https://timestation.clickbooster.io/api/employees/print/${employeeData.id}`);
            if (!response.ok) throw new Error('Failed to download card');
            
            // Get the blob from the response
            const blob = await response.blob();
            
            // Create a download link
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `employee-card-${employeeData.employee_id}.pdf`; // or whatever extension your API returns
            document.body.appendChild(link);
            link.click();
            
            // Cleanup
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading card:', error);
            // Add toast notification here if you're using one
        }
    };

    if (!employeeData) {
        return <div>Employee not found</div>;
    }

    return (
        <>
            <Navbar />
            <div className="p-4 mx-5 my-16  bg-gray-100 rounded-md shadow-lg md:mx-10">
                {/* Header */}
                <div className="flex flex-col items-start justify-between mb-4 md:flex-row md:items-center">
                    <h2 className="mb-4 text-2xl font-bold text-blue-600 md:mb-0">Employee Details</h2>
                    <div className="flex flex-col w-full gap-2 md:flex-row md:w-auto">
                        <button className="w-full px-4 py-2 text-white bg-blue-500 rounded-md md:w-auto hover:bg-blue-600">
                            Department Membership
                        </button>
                        <button 
                            onClick={handlePrintCard}
                            className="w-full px-4 py-2 text-white bg-blue-500 rounded-md md:w-auto hover:bg-blue-600"
                        >
                            Print Card
                        </button>
                        <button 
                            onClick={() => navigate(`/ManualPunch/${employeeData.id}`)}
                            className="w-full px-4 py-2 text-white bg-blue-500 rounded-md md:w-auto hover:bg-blue-600"
                        >
                            Manual Punch
                        </button>
                    </div>
                </div>

                {/* Content Section */}
                <div className="grid gap-4 md:grid-cols-3">
                    {/* Employee Info */}
                    <div className="p-4 bg-white rounded-md shadow md:col-span-2">
                        <h3 className="mb-2 text-lg font-semibold text-gray-800">Employee Info</h3>
                        <p>
                            <span className="font-bold">Name:</span> {employeeData.name}
                        </p>
                        <p>
                            <span className="font-bold">Employee ID:</span> {employeeData.employee_id}
                        </p>
                        <p>
                            <span className="font-bold">Title:</span> {employeeData.title}
                        </p>
                        <p>
                            <span className="font-bold">Email:</span>{' '}
                            <a href={`mailto:${employeeData.email}`} className="text-blue-500">
                                {employeeData.email}
                            </a>
                        </p>
                        <p>
                            <span className="font-bold">Department:</span> {employeeData.department}
                        </p>
                        <p>
                            <span className="font-bold">Hourly Rate:</span> ${parseFloat(employeeData.hourly_rate).toFixed(2)}
                        </p>
                        <p>
                            <span className="font-bold">Permissions:</span>{' '}
                            <div className="flex flex-col gap-1 mt-2">
                                {employeeData.permission_app && (
                                    <span className="inline-block px-2 py-1 text-xs text-green-600 bg-green-100 rounded w-fit">
                                        App Access
                                    </span>
                                )}
                                {employeeData.permission_site && (
                                    <span className="inline-block px-2 py-1 text-xs text-purple-600 bg-purple-100 rounded w-fit">
                                        Site Access
                                    </span>
                                )}
                            </div>
                        </p>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                    </div>

                    {/* Employee Card with QR Code */}
                    <div className="p-4 text-center bg-white rounded-md shadow">
                        <h3 className="mb-2 text-lg font-semibold text-gray-800">Employee Card</h3>
                        <div className="inline-block p-4 border rounded-md">
                            <p className="font-semibold text-gray-700">TimeStation</p>
                            <p className="mb-4 text-gray-700">{employeeData.name}</p>

                            {qrCodeData ? (
                                <div className="mb-4">
                                    <img
                                        src={`data:image/svg+xml;base64,${qrCodeData.qr_code}`}
                                        alt="Employee QR Code"
                                        className="w-20 h-20 mx-auto"
                                    />
                                </div>
                            ) : (
                                <div className="flex items-center justify-center w-20 h-20 mx-auto mb-2 text-2xl text-white bg-blue-500 rounded-full">
                                    {employeeData.name.charAt(0).toUpperCase()}
                                </div>
                            )}

                            <p className="text-xs text-gray-500">Employee ID: {employeeData.employee_id}</p>
                            <p>
                                <span className="font-bold">Department:</span> {employeeData.department}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ManualPunchDetail /> */}
            <Footer />
        </>
    );
};

export default EmployeeCard;
