import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const PasswordSet = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        token: '',
        expires: '',
        signature: ''
    });
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Get URL parameters
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    // Set the form data when component mounts
    React.useEffect(() => {
        const data = {
            email: queryParams.get('email') || '',
            token: queryParams.get('token') || '',
            expires: queryParams.get('expires') || '',
            signature: queryParams.get('signature') || ''
        };
        setFormData(prev => ({ ...prev, ...data }));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await axios.post('https://timestation.clickbooster.io/password-update', {
                email: formData.email,
                password: formData.password,
                password_confirmation: formData.password_confirmation,
                token: formData.token,
                expires: formData.expires,
                signature: formData.signature
            });

            if (response.data.success) {
                // Show success message (optional)
                alert('Password updated successfully!');
                // Redirect to login page
                navigate('/login');
            }
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred while updating password');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="container px-4 mx-auto">
            <div className="flex items-center justify-center min-h-screen">
                <div className="w-full max-w-md">
                    <div className="p-8 bg-white rounded-lg shadow-lg">
                        <h2 className="mb-6 text-2xl font-bold text-center text-gray-800">
                            Set New Password
                        </h2>
                        
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {/* Email Field (Read-only) */}
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    value={formData.email}
                                    readOnly
                                    className="w-full px-3 py-2 text-gray-500 border border-gray-300 rounded-md bg-gray-50"
                                />
                            </div>

                            {/* Password Field */}
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-700">
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    value={formData.password}
                                    onChange={(e) => setFormData({...formData, password: e.target.value})}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                />
                            </div>

                            {/* Confirm Password Field */}
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-700">
                                    Confirm Password
                                </label>
                                <input
                                    type="password"
                                    value={formData.password_confirmation}
                                    onChange={(e) => setFormData({...formData, password_confirmation: e.target.value})}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                />
                            </div>

                            {/* Hidden Fields */}
                            <input type="hidden" value={formData.token} />
                            <input type="hidden" value={formData.expires} />
                            <input type="hidden" value={formData.signature} />

                            {/* Error Message */}
                            {error && (
                                <div className="text-sm text-red-500">
                                    {error}
                                </div>
                            )}

                            {/* Submit Button with loading state */}
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="w-full px-4 py-2 text-white transition-colors bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isSubmitting ? 'Updating...' : 'Update Password'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordSet;

