import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

const Settings = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState({
        company_name: '',
        attendance_mode: '',
        enable_gps_tracking: false,
        require_location: false,
        enable_covid_screening: false,
        time_rounding: '5 Minutes',
        display_round_in_out_times: false,
        date_format: 'MM/DD/YY',
        time_format: '12 Hours (AM/PM)'
    });

    // Fetch settings based on latest entry
    const fetchSettings = async () => {
        try {
            const userId = localStorage.getItem('user_id');

            if (!userId) {
                toast.error('Please login first');
                navigate('/login');
                return;
            }

            const response = await fetch(`https://timestation.clickbooster.io/api/settings`);
            if (!response.ok) {
                throw new Error('Failed to fetch settings');
            }

            const data = await response.json();
            console.log('Fetched all settings:', data);

            // Find the latest entry based on `created_at` or highest `id`
            const latestSettings = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];
            console.log('Latest settings:', latestSettings);

            setSettings({
                company_name: latestSettings.company_name || '',
                attendance_mode: latestSettings.attendance_mode || '',
                enable_gps_tracking: latestSettings.enable_gps_tracking || false,
                require_location: latestSettings.require_location || false,
                enable_covid_screening: latestSettings.enable_covid_screening || false,
                time_rounding: latestSettings.time_rounding || '5 Minutes',
                display_round_in_out_times: latestSettings.display_round_in_out_times || false,
                date_format: latestSettings.date_format || 'MM/DD/YY',
                time_format: latestSettings.time_format || '12 Hours (AM/PM)'
            });
        } catch (error) {
            console.error('Error fetching settings:', error);
            toast.error('Failed to fetch settings');
        } finally {
            setLoading(false);
        }
    };

    // Save settings with user_id
    const handleSave = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const userId = localStorage.getItem('user_id');

            const requestBody = {
                user_id: parseInt(userId),
                company_name: settings.company_name,
                attendance_mode: settings.attendance_mode,
                enable_gps_tracking: settings.enable_gps_tracking,
                require_location: settings.require_location,
                enable_covid_screening: settings.enable_covid_screening,
                time_rounding: settings.time_rounding,
                display_round_in_out_times: settings.display_round_in_out_times,
                date_format: settings.date_format,
                time_format: settings.time_format
            };

            console.log('Saving settings:', requestBody);

            const response = await fetch('https://timestation.clickbooster.io/api/settings', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            const data = await response.json();
            console.log('API Response:', data);

            if (!response.ok) {
                throw new Error(data.message || 'Failed to save settings');
            }

            toast.success('Settings saved successfully!');
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        if (!userId) {
            toast.error('Please login to access settings');
            navigate('/login');
            return;
        }
        fetchSettings();
    }, [navigate]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setSettings(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

   

    return (
        <>
            <Navbar />

            <div className="container px-4 py-8 ">
                <div className="w-[80%] mx-auto lg:w-[80%] md:w-[90%] sm:w-[95%]">
                    <div className="w-full p-6 bg-white rounded-lg shadow-md">
                        <h1 className="mb-8 text-3xl font-bold text-gray-800">Settings</h1>

                        <div className="grid grid-cols-2 gap-4 mb-8 btn__grp sm:grid-cols-3 lg:grid-cols-5">
                            <Button text="Administrators" link="/Administrator" width="100%" />
                            <Button text="Devices" link="/DownloadUser" width="100%" />
                            <Button text="Custom Fields" link="/CreateCustomField" width="100%" />
                            <Button text="Upgrade Account" link="/PricingUser" width="100%" />
                            <Button text="API Keys" link="/APIKeys" width="100%" />
                        </div>
                        <form onSubmit={handleSave} className="space-y-6">
                            {/* Company Name */}
                            <div className="form-group">
                                <label className="block mb-2">Company Name</label>
                                <input
                                    type="text"
                                    name="company_name"
                                    value={settings.company_name}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>

                            {/* Attendance Mode */}
                            <div className="form-group">
                                <label className="block mb-2">Attendance Mode</label>
                                <select
                                    name="attendance_mode"
                                    value={settings.attendance_mode}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                >
                                    <option value="Attendance and Time Tracking">Attendance and Time Tracking</option>
                                    <option value="Attendance Only">Attendance Only</option>
                                </select>
                            </div>

                            {/* GPS Tracking */}
                            <div className="form-group">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="enable_gps_tracking"
                                        checked={settings.enable_gps_tracking}
                                        onChange={handleInputChange}
                                        className="mr-2"
                                    />
                                    Enable GPS Tracking
                                </label>
                            </div>

                            {/* Require Location */}
                            <div className="form-group">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="require_location"
                                        checked={settings.require_location}
                                        onChange={handleInputChange}
                                        className="mr-2"
                                    />
                                    Require Location
                                </label>
                            </div>

                            {/* COVID Screening */}
                            <div className="form-group">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="enable_covid_screening"
                                        checked={settings.enable_covid_screening}
                                        onChange={handleInputChange}
                                        className="mr-2"
                                    />
                                    Enable COVID Screening
                                </label>
                            </div>

                            {/* Time Rounding */}
                            <div className="form-group">
                                <label className="block mb-2">Time Rounding</label>
                                <select
                                    name="time_rounding"
                                    value={settings.time_rounding}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                >
                                    <option value="5 Minutes">5 Minutes</option>
                                    <option value="10 Minutes">10 Minutes</option>
                                </select>
                            </div>

                            {/* Display Round In/Out */}
                            <div className="form-group">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="display_round_in_out_times"
                                        checked={settings.display_round_in_out_times}
                                        onChange={handleInputChange}
                                        className="mr-2"
                                    />
                                    Display Round In/Out Times
                                </label>
                            </div>

                            {/* Date Format */}
                            <div className="form-group">
                                <label className="block mb-2">Date Format</label>
                                <select
                                    name="date_format"
                                    value={settings.date_format}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                >
                                    <option value="MM/DD/YY">MM/DD/YY</option>
                                    <option value="DD/MM/YY">DD/MM/YY</option>
                                </select>
                            </div>

                            {/* Time Format */}
                            <div className="form-group">
                                <label className="block mb-2">Time Format</label>
                                <select
                                    name="time_format"
                                    value={settings.time_format}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                >
                                    <option value="12 Hours (AM/PM)">12 Hours (AM/PM)</option>
                                    <option value="24 Hours">24 Hours</option>
                                </select>
                            </div>

                            <button
                                type="submit"
                                disabled={loading}
                                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 disabled:opacity-50"
                            >
                                {loading ? 'Saving...' : 'Save Settings'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );
};

export default Settings;
