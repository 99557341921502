import React from "react";
import Pricingcard from "../../../components/PricingCards";
import Footer from "../../../components/Footer";
import Button from "../../../components/Button";
import FirstNavbar from "../../../components/LandingPage/FirstNavbar";


const Pricing = () => {
    return (
        <>
            <FirstNavbar/>
            <Pricingcard />
            <div className="container d-grid place-items-center my-10 md:px-24 px-10">
                <Button text="Sign up for free" link="/SignUp" />
            </div>
            <Footer />


        </>
    )
}

export default Pricing;