import React from "react";
import { FaExclamationCircle, FaUserPlus } from "react-icons/fa";
import Button from "./Button";


const ActivityPunch = () => {
    return (
        <>
         
            <div className="container my-10">
                <div className="flex flex-col items-center justify-start bg-gray-50 p-6">
                    <h2 className="text-4xl font-semibold text-blue-600">Employee Activity</h2>

                    <div className="flex flex-col items-center mt-6">
                        {/* <FaExclamationCircle className="text-6xl text-gray-400 mb-4" />
                    <p className="text-gray-500 mb-2">No activity recorded yet.</p>
                    <p className="text-gray-500 mb-4">
                        Click the button below to add a new employee and start tracking activity.
                    </p> */}

                        <div className="flex flex-col space-y-4 w-full sm:w-auto">
                            <Button
                                text="Create Manual Punch"
                                link="/ManualPunch"
                                icon={<FaUserPlus />}
                                width="100%"
                            />
                        </div>
                    </div>

                    <div className="mt-4 w-full shadow-lg ">
                        <div className="bg-blue-500 text-white py-2 px-4 rounded-t-md">
                            Employee Activity
                        </div>
                        <div className="bg-white py-4 px-6 rounded-b-md text-center text-gray-500">
                            This Employee doesn't have any punch activity yet
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default ActivityPunch;
