import React, { useState, useEffect } from "react";

const SearchForm = () => {
  const [departments, setDepartments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState({ employees: [] });

  // Fetch departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const user_id = localStorage.getItem('user_id');
        const response = await fetch(`https://timestation.clickbooster.io/api/department/user/${user_id}`);
        const data = await response.json();
        if (data.status === "success") {
          setDepartments(data.data);
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, []);

  // Handle search
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://timestation.clickbooster.io/api/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: searchQuery }),
      });
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  // Add this new function after the handleSearch function
  const handleClearSearch = () => {
    setSearchQuery("");
    setSearchResults({ employees: [] });
  };

  return (
    <div className="mx-5">
      <form onSubmit={handleSearch} className="max-w-4xl p-6 mx-auto my-10 bg-gray-100 rounded-lg shadow-md">
        <div className="grid items-center grid-cols-1 gap-4 md:grid-cols-3">
          {/* Search Field */}
          <div className="md:col-span-2">
            <label
              htmlFor="search"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Search:
            </label>
            <input
              id="search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by Name, Department, Title, ID, Email..."
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            />
            <a href="#" className="block mt-2 text-sm text-blue-500 hover:underline">
              Have an employee list?
            </a>
          </div>

          {/* Department Dropdown */}
          <div>
            <label
              htmlFor="department"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              Department:
            </label>
            <select
              id="department"
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">- All Departments -</option>
              {departments.map((dept) => (
                <option key={dept.id} value={dept.id}>
                  {dept.dp_name}
                </option>
              ))}
            </select>
            <div className="flex items-center mt-2">
              <input
                id="current-department"
                type="checkbox"
                className="w-4 h-4 text-blue-600 border-gray-300 rounded"
              />
              <label
                htmlFor="current-department"
                className="ml-2 text-sm text-gray-700"
              >
                Show Current Department
              </label>
            </div>
          </div>
        </div>

        {/* Status Section */}
        <div className="mt-6">
          <fieldset>
            <legend className="mb-2 text-sm font-medium text-gray-700">Status:</legend>
            <div className="flex items-center space-x-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="status"
                  value="all"
                  className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">All</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="status"
                  value="in"
                  className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-700">In</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="status"
                  value="out"
                  className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                  defaultChecked
                />
                <span className="ml-2 text-sm text-gray-700">Out</span>
              </label>
            </div>
            <div className="flex items-center mt-2">
              <input
                id="deleted-employees"
                type="checkbox"
                className="w-4 h-4 text-blue-600 border-gray-300 rounded"
              />
              <label
                htmlFor="deleted-employees"
                className="ml-2 text-sm text-gray-700"
              >
                Show Deleted Employees
              </label>
            </div>
          </fieldset>
        </div>

        {/* Update the button section to include both buttons */}
        <div className="flex gap-4 mt-6">
          <button
            type="submit"
            className="px-6 py-2 font-medium text-white bg-blue-500 rounded-lg shadow md:w-auto hover:bg-blue-600"
          >
            Search
          </button>
          <button
            type="button"
            onClick={handleClearSearch}
            className="px-6 py-2 font-medium text-gray-700 bg-gray-200 rounded-lg shadow md:w-auto hover:bg-gray-300"
          >
            Clear Search
          </button>
        </div>
      </form>

      {/* Display search results */}
      {searchResults.employees && searchResults.employees.length > 0 && (
        <div className="mt-6 overflow-x-auto bg-white rounded-lg shadow-lg">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800">Search Results</h2>
            <p className="mt-1 text-sm text-gray-600">Found {searchResults.employees.length} employees</p>
          </div>
          
          <table className="w-full table-auto">
            <thead>
              <tr className="border-b border-gray-200 bg-gray-50">
                <th className="px-6 py-4 text-sm font-semibold text-left text-gray-600 uppercase">ID</th>
                <th className="px-6 py-4 text-sm font-semibold text-left text-gray-600 uppercase">Name</th>
                <th className="px-6 py-4 text-sm font-semibold text-left text-gray-600 uppercase">Title</th>
                <th className="px-6 py-4 text-sm font-semibold text-left text-gray-600 uppercase">Email</th>
                <th className="px-6 py-4 text-sm font-semibold text-left text-gray-600 uppercase">Department</th>
                <th className="px-6 py-4 text-sm font-semibold text-left text-gray-600 uppercase">Hourly Rate</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.employees.map((employee, index) => {
                const department = departments.find(dept => dept.id === employee.department_id);
                return (
                  <tr 
                    key={employee.id} 
                    className={`
                      border-b border-gray-200 hover:bg-blue-50 transition-colors duration-200
                      ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                    `}
                  >
                    <td className="px-6 py-4">
                      <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        {employee.employee_id}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <div className="font-medium text-gray-900">{employee.name}</div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-600">{employee.title}</div>
                    </td>
                    <td className="px-6 py-4">
                      <a href={`mailto:${employee.email}`} className="text-sm text-blue-600 hover:text-blue-800">
                        {employee.email}
                      </a>
                    </td>
                    <td className="px-6 py-4">
                      <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        {department ? department.dp_name : 'N/A'}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm font-medium text-gray-900">
                        ${parseFloat(employee.hourly_rate).toFixed(2)}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SearchForm;
