import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ActivityPunch from "../../components/ActivityPunch";




const Activity = () => {
    return (
        <>
            <Navbar />
            <ActivityPunch/>
            <Footer />

        </>
    )
}

export default Activity;