import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/logo-doubly.png';
import profileImage from '../../assets/images/logo.png';
import Logout from '../Logout';
import { Link } from 'react-router-dom';

const NavbarUser = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [username, setUsername] = useState('User');

    useEffect(() => {
        const storedUserName = localStorage.getItem('userName');
        if (storedUserName) {
            setUsername(storedUserName);
        }
    }, []);




    const toggleMenu = () => setIsOpen(!isOpen);
    const toggleProfileMenu = () => setProfileOpen(!profileOpen);

    return (
        <nav className="p-4 bg-black shadow-md">
            {/* Large Screen Navbar */}
            <div className="container items-center justify-between hidden mx-auto lg:flex">
                {/* Logo */}
                <Link to="/HomeSecond" className="text-lg font-bold text-blue-900">
                    <img src={logo} width="130px" alt="Logo" />
                </Link>

                {/* Desktop Menu */}
                <div className="flex items-center space-x-4">
                    <Link to="/HomeUser" className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700">Home</Link>
                    <Link to="/ActivityUser" className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700">Activity</Link>
                    <Link to="/UserReport" className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700">Reports</Link>
                    <Link to="/SupportUser" className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700">Support</Link>

                    {/* User Profile Dropdown */}
                    <div className="relative">
                        <button
                            onClick={toggleProfileMenu}
                            className="flex items-center p-2 space-x-2 font-semibold text-white rounded-md hover:bg-blue-700"
                        >
                            <img
                                src={profileImage}
                                alt="User Avatar"
                                className="object-cover w-12 h-12 p-1 border-4 rounded-full shadow-lg"
                            />
                            <span>{username}</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </button>
                        {/* Profile Dropdown Menu */}
                        {profileOpen && (
                            <div className="absolute right-0 z-50 w-48 p-4 mt-2 bg-white rounded-md shadow-lg">
                                <Link to="/ProfileInfoPage" className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200">
                                    Profile
                                </Link>
                                <Logout />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Mobile Screen Navbar */}
            <div className="container flex items-center justify-between mx-auto lg:hidden">
                <Link to="/" className="text-lg font-bold text-blue-900">
                    <img src={logo} width="130px" alt="Logo" />
                </Link>
                <button className="text-white" onClick={toggleMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <div className="absolute px-6 left-0 z-50 flex flex-col items-center w-full py-4 mt-10 space-y-4 bg-white shadow-lg lg:hidden top-16">
                    <Link to="/HomeUser" className="p-2 font-semibold transition duration-300 rounded-md hover:bg-blue-700 hover:text-white w-full">Home</Link>
                    <Link to="/ActivityUser" className="p-2 font-semibold transition duration-300 rounded-md hover:bg-blue-700 hover:text-white w-full">Activity</Link>
                    <Link to="/UserReport" className="p-2 font-semibold transition duration-300 rounded-md hover:bg-blue-700 hover:text-white w-full">Reports</Link>
                    <Link to="/SupportUser" className="p-2 font-semibold transition duration-300 rounded-md hover:bg-blue-700 hover:text-white w-full">Support</Link>


                    {/* Mobile Profile Section */}
                    <div className="flex flex-col items-center w-full">
                        <button onClick={toggleProfileMenu} className="flex items-center p-2 space-x-2 font-semibold text-gray-800 rounded-md w-full hover:text-white hover:bg-blue-700">
                            <img src={profileImage} alt="User Avatar" className="object-cover w-8 h-8 rounded-full" />
                            <span>{username}</span>
                        </button>
                        {profileOpen && (
                            <div className="w-48 p-4 mt-2 bg-white rounded-md shadow-lg">
                                <Link to="/ProfileInfoPage" className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200">Profile</Link>
                                {/* <Link to="/UserProfile" className="block px-4 py-2 mb-4 text-sm text-gray-800 hover:bg-gray-200">Settings</Link> */}
                                <Logout />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </nav>
    );
};

export default NavbarUser;
