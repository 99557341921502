import React from "react";
import Footer from "../../components/Footer";
import DownloadSection from "../../components/LandingPage/DownloadSection";
import Navbar from "../../components/Navbar";

const DownloadUser = () => {
    return (
        <>
            <Navbar/>
            <DownloadSection />
            <Footer />
        </>

    )


}

export default DownloadUser;