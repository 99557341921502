import React from "react";
import { FaExclamationCircle, FaUserPlus } from "react-icons/fa";
import Button from "../Button";


const ActivityUserPunch = () => {
    return (
        <>
         
            <div className="container my-20">
                <div className="flex flex-col items-center justify-start p-6 bg-gray-50">
                    <h2 className="text-4xl font-semibold text-blue-600">Employee Activity</h2>

                    <div className="flex flex-col items-center mt-6">
                        {/* <FaExclamationCircle className="mb-4 text-6xl text-gray-400" />
                    <p className="mb-2 text-gray-500">No activity recorded yet.</p>
                    <p className="mb-4 text-gray-500">
                        Click the button below to add a new employee and start tracking activity.
                    </p> */}

                        <div className="flex flex-col w-full space-y-4 sm:w-auto">
                            <Button
                                text="Create Manual Punch"
                                link="/ManualUserPunch"
                                icon={<FaUserPlus />}
                                width="100%"
                            />
                        </div>
                    </div>

                    {/* <div className="w-full mt-4 shadow-lg ">
                        <div className="px-4 py-2 text-white bg-blue-500 rounded-t-md">
                            Employee Activity
                        </div>
                        <div className="px-6 py-4 text-center text-gray-500 bg-white rounded-b-md">
                            This Employee doesn't have any punch activity yet
                        </div>
                    </div> */}
                </div>
            </div>
            
        </>
    );
};

export default ActivityUserPunch;
