import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FAQS = () => {
    const [faqs, setFAQs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [currentFAQ, setCurrentFAQ] = useState({ id: null, question: "", answer: "" });

    const BASE_URL = "https://timestation.clickbooster.io/api/faqs";

    // Fetch FAQs on load
    useEffect(() => {
        loadFAQs();
    }, []);

    const loadFAQs = async () => {
        try {
            const response = await axios.get(BASE_URL);
            if (response.data && response.data.data) {
                setFAQs(response.data.data);
                toast.success('FAQs fetched successfully!');
            } else {
                throw new Error("FAQ data not formatted as expected");
            }
        } catch (error) {
            console.error("Error fetching FAQs:", error);
            toast.error('Failed to fetch FAQs.');
        }
    };

    // Create FAQ
    const createFAQ = async (faq) => {
        try {
            await axios.post(BASE_URL, faq);
            loadFAQs();
            toast.success('FAQ created successfully!');
        } catch (error) {
            console.error("Error creating FAQ:", error);
            toast.error('Failed to create FAQ.');
        }
    };

    // Update FAQ
    const updateFAQ = async (id, faq) => {
        try {
            await axios.put(`${BASE_URL}/${id}`, faq);
            loadFAQs();
            toast.success('FAQ updated successfully!');
        } catch (error) {
            console.error("Error updating FAQ:", error);
            toast.error('Failed to update FAQ.');
        }
    };

    // Delete FAQ
    const deleteFAQ = async (id) => {
        try {
            await axios.delete(`${BASE_URL}/${id}`);
            loadFAQs();
            toast.success('FAQ deleted successfully!');
        } catch (error) {
            console.error("Error deleting FAQ:", error);
            toast.error('Failed to delete FAQ.');
        }
    };

    // Handle Add/Edit Form Submission
    const handleSubmit = async () => {
        if (modalType === "add") {
            await createFAQ({ question: currentFAQ.question, answer: currentFAQ.answer });
        } else if (modalType === "edit") {
            await updateFAQ(currentFAQ.id, { question: currentFAQ.question, answer: currentFAQ.answer });
        }
        setShowModal(false);
        setCurrentFAQ({ id: null, question: "", answer: "" });
    };

    // Open Modal for Add or Edit
    const openModal = (type, faq = { id: null, question: "", answer: "" }) => {
        setModalType(type);
        setCurrentFAQ(faq);
        setShowModal(true);
    };

    console.log('FAQS DATA',faqs)

    return (
        <>
            <ToastContainer />
            <div className="container px-4 mx-auto mt-10">
                <h1 className="mb-6 text-3xl font-bold text-center">FAQs Management</h1>

                <button
                    onClick={() => openModal("add")}
                    className="px-4 py-2 mb-4 text-white bg-blue-500 rounded shadow"
                >
                    Add FAQ
                </button>

                <table className="min-w-full border border-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-4 py-2 border">ID</th>
                            <th className="px-4 py-2 border">Question</th>
                            <th className="px-4 py-2 border">Answer</th>
                            <th className="px-4 py-2 border">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {faqs.map((faq) => (
                            <tr key={faq.id} className="hover:bg-gray-50">
                                <td className="px-4 py-2 border">{faq.id}</td>
                                <td className="px-4 py-2 border">{faq.question}</td>
                                <td className="px-4 py-2 border">{faq.answer}</td>
                                <td className="px-4 py-2 border">
                                    <button
                                        onClick={() => openModal("edit", faq)}
                                        className="px-3 py-1 mr-2 text-white bg-yellow-500 rounded shadow"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => deleteFAQ(faq.id)}
                                        className="px-3 py-1 text-white bg-red-500 rounded shadow"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {showModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="p-6 bg-white rounded shadow w-96">
                            <h2 className="mb-4 text-xl font-bold">
                                {modalType === "add" ? "Add FAQ" : "Edit FAQ"}
                            </h2>
                            <form>
                                <div className="mb-4">
                                    <label className="block mb-1 text-sm font-medium text-gray-700">
                                        Question
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full px-3 py-2 border border-gray-300 rounded"
                                        placeholder="Enter question"
                                        value={currentFAQ.question}
                                        onChange={(e) =>
                                            setCurrentFAQ({ ...currentFAQ, question: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block mb-1 text-sm font-medium text-gray-700">
                                        Answer
                                    </label>
                                    <textarea
                                        className="w-full px-3 py-2 border border-gray-300 rounded"
                                        rows={3}
                                        placeholder="Enter answer"
                                        value={currentFAQ.answer}
                                        onChange={(e) =>
                                            setCurrentFAQ({ ...currentFAQ, answer: e.target.value })
                                        }
                                    ></textarea>
                                </div>
                            </form>
                            <div className="flex justify-end mt-4">
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="px-4 py-2 mr-2 text-white bg-gray-500 rounded shadow"
                                >
                                    Close
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    className="px-4 py-2 text-white bg-blue-500 rounded shadow"
                                >
                                    {modalType === "add" ? "Add" : "Update"}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default FAQS;
