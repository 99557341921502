import React, { useState, useEffect } from "react";
import NavbarUser from "../../../components/UserComponents/NavbarUser";
import Footer from "../../../components/Footer";

const HomeUser = () => {
    const [punchData, setPunchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const employeeId = localStorage.getItem('emp_id');

    useEffect(() => {
        fetchPunchData();
    }, []);

    const fetchPunchData = async () => {
        try {
            const response = await fetch(`https://timestation.clickbooster.io/api/manual-punches/${employeeId}`);
            const data = await response.json();
            setPunchData(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching punch data:', error);
            setError('Failed to fetch punch data');
            setLoading(false);
        }
    };

    const getCurrentStatus = () => {
        if (punchData.length > 0) {
            const latestPunch = punchData[0];
            const hours = calculateHours(latestPunch.time_in, latestPunch.time_out);
            return {
                timeIn: latestPunch.time_in,
                timeOut: latestPunch.time_out,
                date: latestPunch.date,
                hours: hours,
                notes: latestPunch.notes,
                type: latestPunch.type
            };
        }
        return null;
    };

    const calculateHours = (timeIn, timeOut) => {
        if (!timeIn || !timeOut) return '-';
        
        const startTime = new Date(`1970/01/01 ${timeIn}`);
        const endTime = new Date(`1970/01/01 ${timeOut}`);
        
        const diffInMilliseconds = endTime - startTime;
        const hours = diffInMilliseconds / (1000 * 60 * 60);
        
        return hours.toFixed(2);
    };

    return (
       <>
        <NavbarUser/>
        <div className="p-4 my-20 sm:p-8">
            <h1 className="mb-6 text-2xl font-bold text-blue-600">Account Home</h1>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                {/* Recent Activity */}
                <div className="border rounded-md shadow-md">
                    <div className="p-3 text-lg font-bold text-white bg-blue-600 rounded-t-md">
                        Recent Activity
                    </div>
                    {loading ? (
                        <div className="p-4">Loading...</div>
                    ) : error ? (
                        <div className="p-4 text-red-600">{error}</div>
                    ) : (
                        <table className="w-full text-sm border-collapse">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="p-3 text-left border">Date</th>
                                    <th className="p-3 text-left border">Time In</th>
                                    <th className="p-3 text-left border">Time Out</th>
                                    <th className="p-3 text-left border">Hours</th>
                                    <th className="p-3 text-left border">Notes</th>
                                    <th className="p-3 text-left border">Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {punchData.map((punch) => (
                                    <tr key={punch.id} className="hover:bg-gray-50">
                                        <td className="p-3 border">{punch.date}</td>
                                        <td className="p-3 border">{punch.time_in}</td>
                                        <td className="p-3 border">{punch.time_out}</td>
                                        <td className="p-3 border">{calculateHours(punch.time_in, punch.time_out)}</td>
                                        <td className="p-3 border">{punch.notes}</td>
                                        <td className="p-3 border">{punch.type}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>

                {/* Web Punch */}
                <div className="border rounded-md shadow-md">
                    <div className="p-3 text-lg font-bold text-white bg-blue-600 rounded-t-md">
                        Current Status
                    </div>
                    <div className="p-4">
                        {loading ? (
                            <div>Loading...</div>
                        ) : error ? (
                            <div className="text-red-600">{error}</div>
                        ) : getCurrentStatus() ? (
                            <div className="p-3 bg-gray-100 border rounded-md">
                                <p><strong>Date:</strong> {getCurrentStatus().date}</p>
                                <p><strong>Time In:</strong> {getCurrentStatus().timeIn}</p>
                                <p><strong>Time Out:</strong> {getCurrentStatus().timeOut}</p>
                                <p><strong>Hours:</strong> {getCurrentStatus().hours}</p>
                                <p><strong>Notes:</strong> {getCurrentStatus().notes}</p>
                                <p><strong>Type:</strong> {getCurrentStatus().type}</p>
                            </div>
                        ) : (
                            <div>No punch data available</div>
                        )}
                        <button className="px-4 py-2 mt-4 text-white bg-green-500 rounded hover:bg-green-600">
                            Punch In
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
       </>
    );
};

export default HomeUser;
