import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

const UserProfile = () => {
    const [formData, setFormData] = useState({
        current_password: '',
        new_password: '',
        new_password_confirmation: ''
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validation checks
        if (formData.new_password !== formData.new_password_confirmation) {
            toast.error('New passwords do not match!');
            setLoading(false);
            return;
        }

        if (formData.new_password.length < 8) {
            toast.error('Password must be at least 8 characters long');
            setLoading(false);
            return;
        }

        // Retrieve the user data and token from localStorage
        const user = JSON.parse(localStorage.getItem('user'));
        const token = localStorage.getItem('token');
        const userId = user ? user.user_id : null; // Assuming user_id is stored in this manner

        if (!token || !userId) {
            toast.error('You are not logged in. Please login again.');
            localStorage.clear();
            window.location.href = '/login';
            return;
        }

        try {
            const response = await fetch('https://timestation.clickbooster.io/api/change-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json'
                },
                body: JSON.stringify({
                    user_id: userId,
                    current_password: formData.current_password,
                    new_password: formData.new_password,
                    new_password_confirmation: formData.new_password_confirmation
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to change password');
            }

            toast.success('Password changed successfully!');
            setFormData({ current_password: '', new_password: '', new_password_confirmation: '' });
        } catch (err) {
            console.error('Password change error:', err);
            toast.error(err.message || 'Failed to change password. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            toast.error('Please login to access this page');
            window.location.href = '/login';
        }
    }, []);

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Navbar />
            <div className="container max-w-lg p-4 mx-auto my-10">
                <h1 className="mb-6 text-2xl font-semibold text-center">Change Password</h1>
                <div className="p-6 bg-white rounded-lg shadow-md card">
                    <form onSubmit={handlePasswordChange}>
                        <div className="mb-4 form-group">
                            <label htmlFor="current_password" className="block mb-2 text-sm font-medium">
                                Current Password:
                            </label>
                            <input
                                type="password"
                                id="current_password"
                                value={formData.current_password}
                                onChange={handleChange}
                                className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                                required
                            />
                        </div>
                        <div className="mb-4 form-group">
                            <label htmlFor="new_password" className="block mb-2 text-sm font-medium">
                                New Password:
                            </label>
                            <input
                                type="password"
                                id="new_password"
                                value={formData.new_password}
                                onChange={handleChange}
                                className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                                required
                                minLength="8"
                            />
                        </div>
                        <div className="mb-6 form-group">
                            <label htmlFor="new_password_confirmation" className="block mb-2 text-sm font-medium">
                                Confirm New Password:
                            </label>
                            <input
                                type="password"
                                id="new_password_confirmation"
                                value={formData.new_password_confirmation}
                                onChange={handleChange}
                                className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                                required
                                minLength="8"
                            />
                        </div>
                        <Button
                            text={loading ? 'Changing Password...' : 'Change Password'}
                            type="submit"
                            onClick={handlePasswordChange}
                            width="100%"
                        />
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default UserProfile;
