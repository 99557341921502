import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo-doubly.png';
import profileImage from '../assets/images/logo.png';
import Logout from './Logout';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [username, setUsername] = useState(() => localStorage.getItem('userName') || '');
    const location = useLocation();

    // Close menus when route changes
    useEffect(() => {
        setIsOpen(false);
        setProfileOpen(false);
    }, [location.pathname]);

    // Memoize navigation items to prevent unnecessary re-renders
    const navigationItems = useMemo(() => [
        { path: '/HomeSecond', label: 'Home' },
        { path: '/Employee', label: 'Employees' },
        { path: '/Departments', label: 'Departments' },
        { path: '/RunReport', label: 'Reports' },
        { path: '/Settings', label: 'Settings' },
        { path: '/Support', label: 'Support' },
    ], []);

    const toggleMenu = () => setIsOpen(prev => !prev);
    const toggleProfileMenu = () => setProfileOpen(prev => !prev);

    // Memoize the desktop navigation
    const DesktopNavigation = useMemo(() => (
        <div className="flex items-center space-x-4">
            {navigationItems.map(item => (
                <Link
                    key={item.path}
                    to={item.path}
                    className="p-2 font-semibold text-white transition duration-300 rounded-md hover:bg-blue-700"
                >
                    {item.label}
                </Link>
            ))}
        </div>
    ), [navigationItems]);

    // Memoize the profile section
    const ProfileSection = useMemo(() => (
        <div className="relative">
            <button
                onClick={toggleProfileMenu}
                className="flex items-center p-2 space-x-2 font-semibold text-white rounded-md hover:bg-blue-700"
            >
                <img
                    src={profileImage}
                    alt="User Avatar"
                    className="object-cover w-12 h-12 p-1 border-4 rounded-full shadow-lg"
                />
                <span>{username}</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-5 h-5"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </button>
            {profileOpen && (
                <div className="absolute right-0 z-50 w-48 p-4 mt-2 bg-white rounded-md shadow-lg">
                    <Link to="/Account_Setting" className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200">
                        Profile
                    </Link>
                    <Link to="/UserProfile" className="block px-4 py-2 mb-4 text-sm text-gray-800 hover:bg-gray-200">
                        Change Password
                    </Link>
                    <Logout />
                </div>
            )}
        </div>
    ), [username, profileOpen]);

    return (
        <nav className="p-4 bg-black shadow-md">
            {/* Large Screen Navbar */}
            <div className="container items-center justify-between hidden mx-auto lg:flex">
                <Link to="/HomeSecond" className="text-lg font-bold text-blue-900">
                    <img src={logo} width="130px" alt="Logo" />
                </Link>
                {DesktopNavigation}
                {ProfileSection}
            </div>

            {/* Mobile Screen Navbar */}
            <div className="container flex items-center justify-between mx-auto lg:hidden">
                <Link to="/" className="text-lg font-bold text-blue-900">
                    <img src={logo} width="130px" alt="Logo" />
                </Link>
                <button className="text-white" onClick={toggleMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <div className="absolute left-0 z-50 flex flex-col items-center w-full py-4 mt-10 space-y-4 bg-white shadow-lg lg:hidden top-16">
                    {navigationItems.map(item => (
                        <Link
                            key={item.path}
                            to={item.path}
                            className="p-2 font-semibold transition duration-300 rounded-md hover:bg-blue-700"
                        >
                            {item.label}
                        </Link>
                    ))}
                    {/* Mobile Profile Section */}
                    <div className="flex flex-col items-center w-full">
                        <button onClick={toggleProfileMenu} className="flex items-center p-2 space-x-2 font-semibold text-gray-800 rounded-md hover:bg-blue-700">
                            <img src={profileImage} alt="User Avatar" className="object-cover w-8 h-8 rounded-full" />
                            <span>{username}</span>
                        </button>
                        {profileOpen && (
                            <div className="w-48 p-4 mt-2 bg-white rounded-md shadow-lg">
                                <Link to="/Account_Setting" className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200">
                                    Profile
                                </Link>
                                <Link to="/UserProfile" className="block px-4 py-2 mb-4 text-sm text-gray-800 hover:bg-gray-200">
                                    Settings
                                </Link>
                                <Logout />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </nav>
    );
};

export default React.memo(Navbar);
