import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import { FaPlus } from "react-icons/fa";

const APIKeys = () => {
    const [keys, setKeys] = useState([]);

    const fetchKeys = async () => {
        try {
            const response = await fetch('https://timestation.clickbooster.io/api/apikeys');
            const jsonData = await response.json();
            console.log(jsonData); // Inspect the API response structure
            setKeys(jsonData.all_keys); // Adjust this line if the response structure is different
        } catch (err) {
            console.error(err);
        }
    };
    
    useEffect(() => {
        fetchKeys();
    }, []); // Only fetch keys once when the component mounts

    const toggleReveal = (id) => {
        setKeys(
            keys.map((key) =>
                key.id === id ? { ...key, isRevealed: !key.isRevealed } : key
            )
        );
    };

    return (
        <>
            <Navbar />
            <div className="container md:px-24 px-10 my-16">
                <div className="p-4 md:p-8">
                    <h2 className="text-2xl font-semibold mb-4">API Keys</h2>
                    <Button text="Create API Key" link="/CreateApiKey" icon={<FaPlus />} />
                    <div className="w-full overflow-x-auto">
                        <table className="w-full mt-6 mx-auto bg-white shadow-md rounded-lg overflow-hidden">
                            <thead className="bg-blue-600 text-white">
                                <tr>
                                    <th className="p-4 text-left">Edit</th>
                                    <th className="p-4 text-left">Key Name</th>
                                    <th className="p-4 text-left">Key</th>
                                    <th className="p-4 text-left">Created At</th>
                                    <th className="p-4 text-left">Action</th>
                                </tr>
                            </thead>
                            <tbody>
    {Array.isArray(keys) && keys.map(({ id, key_name, key_value, created_at, isRevealed }) => (
        <tr key={id} className="border-b hover:bg-gray-100">
            <td className="p-4">
                <button className="text-yellow-500">
                    ✏️
                </button>
            </td>
            <td className="p-4">{key_name}</td>
            <td className="p-4">
                <span className="bg-gray-100 p-2 rounded-md inline-block w-full max-w-xs overflow-hidden">
                    {isRevealed ? key_value : '••••••••••••'}
                </span>
            </td>
            <td className="p-4">{new Date(created_at).toLocaleDateString()}</td>
            <td className="p-4">
                <button
                    onClick={() => toggleReveal(id)}
                    className="bg-green-500 hover:bg-green-600 text-white py-1 px-3 rounded"
                >
                    {isRevealed ? 'Hide' : 'Reveal'}
                </button>
            </td>
        </tr>
    ))}
</tbody>

                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default APIKeys;
