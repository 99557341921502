import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
// import Button from '../../components/Button';

const CreateDepartment = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        dp_name: '',
        dp_type: '1',
        exclude_from_reports: false,
        use_company_settings: false,
        assign_to_all_employees: false

    });

    // Debug log for initial render
    useEffect(() => {
        console.log('Component mounted');
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id');
        console.log('Token:', token);
        console.log('User ID:', user_id);
    }, []);

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData(prev => {
            const newState = {
                ...prev,
                [e.target.id]: value
            };
            console.log('Form data updated:', newState);
            return newState;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form submitted');

        const user_id = localStorage.getItem('user_id');
        const token = localStorage.getItem('token');

        console.log('Submission details:', {
            user_id,
            token,
            formData
        });

        if (!user_id) {
            toast.error('User not authenticated');
            navigate('/login');
            return;
        }

        try {
            const requestData = {
                user_id: parseInt(user_id),
                dp_name: formData.dp_name,
                dp_type: formData.dp_type,
                exclude_from_reports: formData.exclude_from_reports,
                use_company_settings: formData.use_company_settings,
                assign_to_all_employees: formData.assign_to_all_employees
            };

            console.log('Sending request with data:', requestData);

            const response = await fetch('https://timestation.clickbooster.io/api/departments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(requestData)
            });

            console.log('Response received:', response);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to create department');
            }

            const responseData = await response.json();
            console.log('Success response:', responseData);

            toast.success('Department created successfully');
            navigate('/DepartmentsDetail');
        } catch (error) {
            console.error('Error creating department:', error);
            toast.error(error.message || 'Failed to create department');
        }
    };

    return (
        <>
            <Navbar />
            <div className="flex justify-center p-4 my-10">
                <div className="w-full max-w-md p-6 rounded-lg shadow-lg bg-blue-50">
                    <h2 className="mb-4 text-xl font-bold text-blue-600">Create New Department</h2>

                    {/* Debug info */}


                    <form onSubmit={handleSubmit} className="space-y-4">
                        {/* Department Name */}
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold text-gray-700" htmlFor="dp_name">
                                Department Name:
                            </label>
                            <input
                                type="text"
                                id="dp_name"
                                name="dp_name"
                                value={formData.dp_name}
                                onChange={handleChange}
                                className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                                required
                            />
                        </div>

                        {/* Department Type */}
                        <div className="mb-4">
                            <label className="block mb-1 font-semibold text-gray-700" htmlFor="dp_type">
                                Department Type:
                            </label>
                            <select
                                id="dp_type"
                                name="dp_type"
                                value={formData.dp_type}
                                onChange={handleChange}
                                className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                            >
                                <option value="5">Class</option>
                                <option value="7">Client</option>
                                <option value="1">Department</option>
                                <option value="2">Group</option>
                                <option value="4">Job</option>
                                <option value="9">Job Site</option>
                                <option value="3">Location</option>
                                <option value="6">Office</option>
                                <option value="8">Project</option>
                                <option value="10">Task</option>
                            </select>
                        </div>

                        {/* Checkboxes */}
                        <div className="space-y-3">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="exclude_from_reports"
                                    name="exclude_from_reports"
                                    checked={formData.exclude_from_reports}
                                    onChange={handleChange}
                                    className="w-4 h-4 mr-2 text-blue-600 border-gray-300 rounded"
                                />
                                <label htmlFor="exclude_from_reports" className="text-gray-700">
                                    Exclude from Reports
                                </label>
                            </div>
                            <span className="text-sm text-gray-500">Exclude activity within this department from all reports.</span>

                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="use_company_settings"
                                    name="use_company_settings"
                                    checked={formData.use_company_settings}
                                    onChange={handleChange}
                                    className="w-4 h-4 mr-2 text-blue-600 border-gray-300 rounded"
                                />
                                <label htmlFor="use_company_settings" className="text-gray-700">
                                    Use Company Settings
                                </label>
                            </div>
                            <span className="text-sm text-gray-500">Use the company settings for this department.</span>

                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="assign_to_all_employees"
                                    name="assign_to_all_employees"
                                    checked={formData.assign_to_all_employees}
                                    onChange={handleChange}
                                    className="w-4 h-4 mr-2 text-blue-600 border-gray-300 rounded"
                                />
                                <label htmlFor="assign_to_all_employees" className="text-gray-700">
                                    Assign to All Employees
                                </label>
                            </div>
                            <span className="text-sm text-gray-500">Assign all employees to this department.</span>
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="w-full px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            Create Department
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CreateDepartment;

