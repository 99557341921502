import React from "react";
import { FaExclamationTriangle, FaPlus } from "react-icons/fa";
import Button from "./Button";

const DeviceDownload = () => {
    return (
        <div className="container shadow-xl">
            <div className="flex flex-col  items-center justify-center h-screen bg-gray-50 p-6">
                <h2 className="text-4xl font-semibold text-blue-600">Downlaod</h2>

                <div className="flex flex-col items-center mt-6 text-center">
                    <FaExclamationTriangle className="text-6xl text-gray-400 mb-4" />
                    <p className="text-gray-500 mb-2">No devices associated with your account <br />
                        Click the button below to download the TimeStation App <br />
                        into your smartphone or tablet.</p>

                    <div className="flex flex-col mt-4 space-y-4 w-full sm:w-auto">
                        <Button
                            text="Downlaod App"
                            link="#"
                            icon={<FaPlus />}
                            width="100%"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeviceDownload;
