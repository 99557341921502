import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserFriends, FaPrint, FaMobileAlt } from 'react-icons/fa';
import Button from './Button';

const WelcometoTime = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  // Check for user in local storage and redirect if not found
  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userId = localStorage.getItem('user_id');
    console.log('User Id: ', userId);
    if (!userEmail) {
      navigate('/Login');
    } else {
      setUser(userEmail);
    }
  }, [navigate]);

  console.log('User Email:', user);

  return (
    <div className="container mx-auto my-10 md:my-20 px-4 md:px-24">
      <div className="flex flex-col items-center py-10 md:py-20 px-6 mx-auto bg-gray-50 rounded-lg shadow-lg space-y-6 md:space-y-8">
        <h1 className="text-2xl font-bold text-blue-600 md:text-5xl text-center">
          Welcome to TimeStation
        </h1>
        <p className="text-center text-gray-500 px-4 md:px-0">
          You're only steps away from completing your account setup
        </p>

        {/* Steps Section */}
        <div className="w-full space-y-4">
          {steps.map((step, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row items-center justify-between p-4 bg-blue-100 rounded-lg hover:shadow-md transition-shadow space-y-4 md:space-y-0"
            >
              <div className="flex flex-col md:flex-row items-center md:space-x-4 space-y-4 md:space-y-0 w-full">
                <div className="flex items-center space-x-2">
                  <span className="text-xl text-center md:text-3xl font-bold text-blue-600">{`Step ${step.number}`}</span>
                  <div className="text-3xl md:text-4xl text-blue-600">
                    {step.icon}
                  </div>
                </div>
                <div className="flex flex-col md:ml-4 text-center md:text-left w-full">
                  <span className="text-blue-600 text-lg md:text-2xl font-medium">
                    {step.title}
                  </span>
                </div>
              </div>
              {/* Go Button */}
              <div className="flex justify-center w-full md:w-auto">
                <Button text="Go" link={step.link} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Steps data
const steps = [
  {
    number: 1,
    title: 'Enter, or import your employees',
    icon: <FaUserFriends />,
    link: '/Account_Employees',  // Adjusted to lead directly to the create employees page
  },
  {
    number: 2,
    title: 'Print your Employee Cards',
    icon: <FaPrint />,
    link: '/Employee',
  },
  {
    number: 3,
    title: 'Install TimeStation on a Smartphone or Tablet',
    icon: <FaMobileAlt />,
    link: '/install-app',
  },
];

export default WelcometoTime;
