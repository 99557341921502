import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../pages/EmployeesPage/EmployeePega.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const EmployeesPage = () => {
  const navigate = useNavigate();

  // Function to handle navigation on button click
  const handleCreateNewEmployee = () => {
    navigate('/CreateNewEmployee');
  };

  const handleImportEmployees = () => {
    navigate('/ImportEmployees');
  };

  return (
    <>
      <Navbar/>
      <div className="employee-management-container">
        <h1 className="employee-management-header">Employees</h1>
        <p className="employee-management-subtext">No Employees are currently setup.</p>
        <p className="employee-management-subtext">Click a button below to add your first employee.</p>
        <div className="employee-management-buttons">
          <button className="employee-management-btn employee-create" onClick={handleCreateNewEmployee}>
            Create New Employee
          </button>
          <button className="employee-management-btn employee-import" onClick={handleImportEmployees}>
            Import Employees
          </button>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default EmployeesPage;
