import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const ImportEmployees = () => {
    const [uploadedEmployees, setUploadedEmployees] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            toast.error("No file selected");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        setIsLoading(true);
        try {
            const response = await fetch('https://timestation.clickbooster.io/api/employees/import', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to import data');
            }
            toast.success('File successfully imported');
            await fetchImportedEmployees(); // Fetch the imported employees after successful import
        } catch (error) {
            console.error('Upload error:', error);
            toast.error(error.message || 'Failed to upload data');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchImportedEmployees = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('https://timestation.clickbooster.io/api/employees/imported');
            if (!response.ok) {
                const errorData = await response.json(); // Assuming the server responds with JSON-formatted error messages
                throw new Error(errorData.message || 'Failed to fetch imported employees');
            }
            const data = await response.json();
            setUploadedEmployees(data);
            if (data.length === 0) {
                toast.info('No employee data available.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error(error.message || 'Failed to fetch uploaded employees');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchImportedEmployees(); // Initial fetch to load previously imported employees
    }, []);

    const handleDownloadTemplate = async () => {
        if (uploadedEmployees.length === 0) {
            toast.error("Employee not found");
            return;
        }
        window.location.href = "https://timestation.clickbooster.io/api/employees/export";
    };

    return (
        <>
            <Navbar />
            <div className="flex flex-col items-center min-h-screen p-6 mt-10 bg-gray-50">
                <h1 className="mb-4 text-3xl font-bold text-center text-blue-600">Import Employees</h1>
                
                <div className="grid w-full max-w-4xl grid-cols-1 gap-6 mb-8 md:grid-cols-3">
                    <div className="flex flex-col items-center p-6 text-center rounded-lg shadow-md bg-blue-50">
                        <div className="flex items-center justify-center w-10 h-10 mb-4 text-lg font-semibold text-white bg-gray-600 rounded-full">1</div>
                        <p className="mb-5 font-semibold text-gray-700">Download the user import template</p>
                        <button
                            onClick={handleDownloadTemplate}
                            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700"
                            disabled={isLoading}
                        >
                            Download Template
                        </button>
                    </div>

                    <div className="flex flex-col items-center p-6 text-center rounded-lg shadow-md bg-blue-50">
                        <div className="flex items-center justify-center w-10 h-10 mb-4 text-lg font-semibold text-white bg-gray-600 rounded-full">2</div>
                        <p className="mb-5 font-semibold text-gray-700">Fill in the template with your data</p>
                        <ul className="text-sm text-left text-gray-600">
                            <li>• Add employee details</li>
                            <li>• Add department info</li>
                            <li>• Save the file</li>
                        </ul>
                    </div>

                    <div className="flex flex-col items-center p-6 text-center rounded-lg shadow-md bg-blue-50">
                        <div className="flex items-center justify-center w-10 h-10 mb-4 text-lg font-semibold text-white bg-gray-600 rounded-full">3</div>
                        <p className="mb-5 font-semibold text-gray-700">Upload the completed user import template</p>
                        <input
                            type="file"
                            accept=".xlsx,.xls"
                            onChange={handleFileUpload}
                            className={`block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer p-2.5 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={isLoading}
                        />
                    </div>
                </div>

                {uploadedEmployees.length > 0 && (
                    <div className="w-full max-w-4xl mt-8">
                        <h2 className="mb-4 text-xl font-semibold">Uploaded Employees</h2>
                        <div className="overflow-x-auto bg-white rounded-lg shadow">
                            <table className="w-full">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="p-4 text-left">Name</th>
                                        <th className="p-4 text-left">Employee ID</th>
                                        <th className="p-4 text-left">Email</th>
                                        <th className="p-4 text-left">Hourly Rate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {uploadedEmployees.map((employee, index) => (
                                        <tr key={index} className="border-t">
                                            <td className="p-4">{employee.name}</td>
                                            <td className="p-4">{employee.employee_id}</td>
                                            <td className="p-4">{employee.email}</td>
                                            <td className="p-4">${employee.hourly_rate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default ImportEmployees;
