import React from "react";
import Footer from "../../../components/Footer";
import FAQ from "../../../components/FAQ";
import NavbarUser from "../../../components/UserComponents/NavbarUser";

const SupportUser = () => {
    return(
        <>
        <NavbarUser/>
        <FAQ/>
        <Footer/>

        </>
    )
}

export default SupportUser;