import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className="w-full h-full bg-white p-6 space-y-4 overflow-auto">
                <button 
                    onClick={onClose} 
                    className="absolute top-4 right-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                >
                    Close
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
