import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const ManualPunchDetail = () => {
    const [punchData, setPunchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const employeeId = localStorage.getItem("employeeId");

    useEffect(() => {
        if (!employeeId) {
            setError("Employee ID is not available. Please log in again.");
            setLoading(false);
            return;
        }
        fetchPunchData();
    }, [employeeId]);

    const fetchPunchData = async () => {
        try {
            const response = await fetch(
                `https://timestation.clickbooster.io/api/manual-punches/${employeeId}`
            );
            if (!response.ok) {
                throw new Error(`Error fetching punch data: ${response.status}`);
            }

            const result = await response.json();
            console.log("Fetched Punch Data:", result); 

            if (result.data && Array.isArray(result.data) && result.data.length > 0) {
                setPunchData(result.data);
            } else {
                setPunchData([]);
                console.warn("No punch data available."); 
            }
        } catch (err) {
            console.error("Error fetching punch data:", err);
            setError("Failed to fetch punch data. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const calculateHours = (timeIn, timeOut) => {
        if (!timeIn || !timeOut) return "-";

        const startTime = new Date(`1970-01-01T${timeIn}`);
        const endTime = new Date(`1970-01-01T${timeOut}`);

        const diffInMilliseconds = endTime - startTime;
        const hours = diffInMilliseconds / (1000 * 60 * 60);

        return hours.toFixed(2);
    };

    return (
        <>
            <Navbar />
            <div className="flex flex-col items-center min-h-screen p-6 my-14 bg-gray-50">
                <div className="w-full max-w-4xl p-6 bg-white rounded-lg shadow-md">
                    <h1 className="mb-6 text-2xl font-semibold text-blue-600">
                        Manual Punch Details
                    </h1>

                    {loading && (
                        <div className="p-4 mb-6 text-gray-700 bg-gray-100 rounded-md">
                            Loading punch details...
                        </div>
                    )}

                    {error && (
                        <div className="p-4 mb-6 text-red-700 bg-red-100 rounded-md">
                            {error}
                        </div>
                    )}

                    {!loading && !error && punchData.length > 0 ? (
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Date</th>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Time In</th>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Time Out</th>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Hours</th>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Type</th>
                                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Notes</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {punchData.map((punch, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                            <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">{punch.date}</td>
                                            <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">{punch.time_in}</td>
                                            <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">{punch.time_out}</td>
                                            <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">{calculateHours(punch.time_in, punch.time_out)}</td>
                                            <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">{punch.type}</td>
                                            <td className="px-6 py-4 text-sm text-gray-900">{punch.notes || '-'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="p-4 mb-6 text-yellow-700 bg-yellow-100 rounded-md">
                            No punch details found.
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ManualPunchDetail;
